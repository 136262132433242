const colorSwatches = Array.from(document.querySelectorAll('.color-selector-container'));

const bindEvents = () => {
  colorSwatches.forEach((swatchRow) => {
    const swatches = Array.from(swatchRow.getElementsByTagName('li'));

    swatches.forEach((swatch) => {
      swatch.addEventListener('mouseenter', (e) => {
        onSwatchChange(e.target.firstElementChild, swatchRow);
      });
      swatch.addEventListener('mouseleave', (e) => {
        onSwatchChange(swatchRow.querySelector('li.active a'), swatchRow);
      });
    });
  });
};

const onSwatchChange = (swatch, swatchRow) => {
  const swatchNameElem = swatchRow.querySelector('.selected-swatch-name');
  swatchRow.querySelector('li a.active')?.classList.remove('active');
  if (swatch) {
    swatch.classList.add('active');
    swatchNameElem.textContent = swatch.getAttribute('title');
    if (swatch.closest('.plp-color-swatches') && window.innerWidth > 991) {
      const activePimId = swatch.dataset.pimid;
      Array.from(swatch.closest('.isotope-item').querySelectorAll('img')).forEach((img) => {
        if (img.dataset.pimid === activePimId) img.classList.remove('d-none');
        else img.classList.add('d-none');
      });
    }
  }
};

const init = () => {
  if (_THULEDATA.IsEditing) {
    return;
  }

  bindEvents();
};

document.addEventListener('DOMContentLoaded', function () {
  init();
});
